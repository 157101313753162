import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useRouter } from "vue-router";
import { useWallet } from "@txnlab/use-wallet-vue";
import algosdk, { Transaction } from "algosdk";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'AccountSign',
  props: {
  txn: Transaction,
},
  setup(__props) {

const props = __props;
const router = useRouter();

const { algodClient, activeWallet, activeAddress, transactionSigner } =
  useWallet();
const store = useStore();
const sign = async () => {
  try {
    console.log("props", props);
    console.log("txn", props.txn);
    if (!props.txn) return;
    const atc = new algosdk.AtomicTransactionComposer();
    atc.addTransaction({ txn: props.txn, signer: transactionSigner });
    await store.dispatch(
      "toast/openSuccess",
      "Please check your wallet and sign the tx"
    );
    const res = await atc.execute(algodClient.value, 4);

    if (res.txIDs.length > 0) {
      await store.dispatch(
        "toast/openSuccess",
        "Tx has been submitted to the blockchain."
      );
      //router.push(`/mainnet-v1.0/388592191/question/${res.txIDs[0]}`);
    } else {
      await store.dispatch(
        "toast/openError",
        "Submitted to the blockchain but the call did not returned tx"
      );
    }
  } catch (e: any) {
    await store.dispatch("toast/openError", e.message);
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn btn-primary my-2 me-2",
    onClick: sign
  }, " Sign using " + _toDisplayString(_unref(activeWallet)?.metadata?.name), 1))
}
}

})