export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["404-es hiba - az oldal nem található"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett webhely nem található."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo pénztárca"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új pénztárca"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca név"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca jelszó"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca létrehozása"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca importálása"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárca a böngészőn belül fog tárolódni. A pénztárca megnyitásához, a pénztárcán belüli számlák kijelzéséhez és a tranzakciók aláírásához egy jelszóra van szükség."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azt tanácsoljuk, hogy 12 karakternél hosszabb jelszót használj. A jelszóban legyenek kisbetűk, nagybetűk és különleges karakterek."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca megnyitása"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca kiválasztása"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca jelszava"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erősség"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca megnyitása"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alapműveletek"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan használjuk a Tether-t (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet forráskód"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugrás a kezdőlapra"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím másolása"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Átmásolva a vágólapra"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképezőgép leállítása"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégsem"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betöltés alatt..."])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váltás a navigációban"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák listázása"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új számla"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NTF létrehozás"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GYIK"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelem"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szavazás"])}
  },
  "votemenu": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])},
    "my_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdéseim"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdésem van"])},
    "delegation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegálás"])},
    "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszok"])},
    "trusted_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizalmi lista"])},
    "governance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítás"])},
    "ams01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Coin"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák előállítása"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo szétosztás 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevezés az irányításba"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tömeges fizetés N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák összevonása N->1"])}
  },
  "voteask": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdezz valamit a blokkláncon keresztül"])},
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés címe"])},
    "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez fog megjelenni a kérdéseket tartalmazó táblázatban"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés szövege"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL cím, amely a kérdés további részleteit tartalmazza (kezdd úgy, hogy https://)"])},
    "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Itt adható meg egy URL cím, ahol további információ található a kérdésről"])},
    "max_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelenlegi blokkhoz képest ennyi blokk múlva lesz a kérdés lezárva"])},
    "calculated_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max blokk"])},
    "calculated_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Becsült idő"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kód"])},
    "response_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A válaszlehetőség szövege"])},
    "remove_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszlehetőség törlése"])},
    "responses_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A többiek oly módon fognak válaszolni a kérdésedre, hogy szavaznak az egyes lehetőségekre. Légy szíves, add meg a 2-ik..N-ik válasz kódját és szövegét."])},
    "add_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új válasz felvétele"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés kategória"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudás alapú kategória alapján a szavazati jog átruházása van érvényben"])},
    "submit_question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tegyél föl egy kérdést az ", _interpolate(_named("accountName")), " számla segítségével"])},
    "encryptedVotingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow members to use encrypted voting"])},
    "encryptionAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encryption address - Generate new mnemonics for each voting and store it securly"])},
    "generateNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new encryption mnemonics"])},
    "publishMnemonics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publish encryption key"])}
  },
  "votedelegate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szavazati jog átruházása"])},
    "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szavazati jogodat átruházhatod más valakire, akiben megbízol. Ez lehet a barátod, vagy egy ismert személy."])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha van egy pénzügyekben jártas jóbarátod, akkor a pénzügyi kategóriában átruházhatod rá a szavazati jogodat. Ha a barátod sem szavaz közvetlenül a kérdésekre, akkor átruházhatja a te szavazati jogodat és a saját szavazati jogát a saját belátása szerint az általa ismert szakértőre. Amikor ez a szakértő szavaz, akkor a te szavazati jogodat használja és a te véleményedet képviseli. Mindig szavazhatsz közvetlen módon, még akkor is, ha átruháztad a szavazati jogodat. Ha közvetlenül szavazol, akkor az adott kérdésben a tiéd marad a szavazati jogod."])},
    "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szavazati jogodat több barátod között is szétoszthatod. A szavazati jogod megosztásának súlyozását pontokkal tudod kifejezni. Ha az első barátodnak 50 pontot adtál, a másodiknak 100 pontot, akkor az első barátod a szavazati jogod 50/150 = 33 %-val fog rendelkezni. A szavazati jogod maradékát, 100/150 = 67 %-ot a második barátod kapja."])},
    "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 'Tetszőleges kategória' tartalék kategória. Ha egy adott kérdés esetében nem lett delegálva egyetlen kategória sem, de a 'Tetszőleges kategória' definiálva van, akkor ez lesz használva."])},
    "category_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetszőleges kategória"])},
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla hozzáadása"])},
    "wallet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca cím"])},
    "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla hozzáadása az átruházáshoz"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy adott kategória átruházása"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az átruházás megadható egy adott tudás alapú kategóriában, pl. IT, pénzügy, irányítás"])},
    "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy adott kategória megadása"])},
    "submit_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lenti gomb megnyomásával az átruházással kapcsolatos preferenciák a blokkláncon tárolhatók."])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az ", _interpolate(_named("accountName")), " átruházásainak tárolása a blokkláncon"])}
  },
  "voteoverview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áttekintés"])}
  },
  "votequestions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdések"])}
  },
  "votequestionlist": {
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés címe"])},
    "no_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Légy te az első, aki feltesz egy kérdést"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés szövege"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés ennél a menetnél lett feltéve:"])},
    "maxround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdés eddig a menetig lesz nyitva:"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdező"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az összes kérdés listája"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdés azonosító"])},
    "current_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuális menet"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A menet ideje"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehetőségek"])},
    "voting_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A szavazás lezárult"])},
    "latest_response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az ", _interpolate(_named("accountName")), " utolsó szavazata lesz figyelembe véve"])},
    "vote_button": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Szavazz az ", _interpolate(_named("accountName")), " számlával"])},
    "sum_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A figyelembe vett szavazatok száma"])},
    "sum_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A figyelembe vett érmék összege"])},
    "trusted_list_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredmény a megbízható szavazók listája alapján"])},
    "hypercapitalism_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredmény az 1 érme 1 szavazat alapján"])},
    "vote_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha szavazni szeretnél, akkor légy szíves, add meg a súlyozást. Az egyes válaszoknál nulla és 100 pont közötti súlyozás adható. Ha csak egy választ akarsz kiválasztani, akkor adj neki 100 pontot, a többi válasznak pedig nulla pontot. Ha nem tudod a választ, akkor nem kell szavaznod, illetve az összes válaszhoz azonos pontot rendelhetsz hozzá. Ha az egyik válaszra 100 ponttal szavazol, egy másik válaszra pedig 20 ponttal, akkor az első válaszra a szavazati jogod 100/120 = 83%-ával szavazol, a másodikra 17 %-kal. Ha az összes lehetőséghez 100 pontot rendelsz, akkor a szavazati jogod eloszlása ugyanolyan lesz, mint ha mindegyik lehetőséghez 1 pontot rendeltél volna."])},
    "check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eredmények ellenőrzése"])},
    "sbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple balance results"])},
    "sbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "qbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic balance results"])},
    "qbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "ssar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple single account results"])},
    "ssar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic single account results"])},
    "qsar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "stlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple trusted list results"])},
    "stlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qtlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic trusted list results"])},
    "qtlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "encryptVoteCast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypt my voting decision"])},
    "submit_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit my results for audit purposes"])},
    "mnemonics_required_to_get_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted voting requires mnemonics to properly calculate the results"])}
  },
  "votetl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megbízható szavazók listájának kezelése"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kérdésfeltevő ezen a lapon kezelheti a megbízható számlák listáját. Egy személyhez csak egy megbízható számla tartozhat. Ennek a listának a kezelése a kérdésfeltevő feladata. A szavazás befejeződése után kétféleképpen lesznek a szavazatok összesítve: 'egy személy egy szavazat' alapján, vagy 'egy érme egy szavazat' alapján. A szavazó rendszer nem értelmezi a szavazás eredményét, csupán kiszámítja a szabályok alapján a szavazás eredményét, vagyis figyelembe veszi pl. a szavazatok átruházását, a rész-szavazatokat, a szavazatok érvényességét stb."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla hozzáadása a megbízható szavazók listájához - soronként egy számla"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla eltávolítása a megbízható szavazók listájából - soronként egy számla"])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az ", _interpolate(_named("accountName")), " számlához tartozó megbízható szavazók listájának eltárolása a blokkláncon"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az n-ik menetben létrehozva"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megbízható számla"])},
    "no_tl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Az ", _interpolate(_named("accountName")), " számlához nincsenek megbízható számlák felvéve."])}
  },
  "voteanswerslist": {
    "no_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Még nincs válasz a kérdésre"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menetek száma a válaszadáskor"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszadás ideje"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszadó"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A válaszhoz tartozó json"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizess"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az Algorand címnek"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kérlek"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hálózat"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi szimbólum"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hálózati díj"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés QR-kód használatával"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés Algorand pénztárca használatával"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés web-es pénztárca használatával"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés törölve"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A küldött összeg megérkezett"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vissza a kereskedőhöz"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várakozás a fizetségre"])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szabadon használható, nyílt forráskódú, közvetítők nélküli fizetési gateway"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fogadj el a webhelyeden Algót, USDC-t, USDT-t vagy más egyéb pénzeszközt. A kriptovaluták pénzügyi közvetítő nélküli elfogadása növeli a webhely eladásait és a hasznot."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan működik?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozz létre egy egyedi azonosító szimbólumot, pl. a megrendelés azonosítót"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítsd át a felhasználót a gateway-re"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó közvetlenül a te Algorand számládra fizet"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A felhasználó a sikeres fizetési tranzakció után vissza lesz irányítva a webhelyedre"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használd a pénzedet. Az USDT-t a pénzváltóhelyen USD-re válthatod, vagy az UDST-vel fizetni tudsz más árukért vagy szolgáltatásokért."])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mennyire biztonságos mindez?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te határozod meg a fizetés részleteit. Te állítod be a sikeres és a törölt fizetéshez tartozó URL címeket. Értesítést kapsz, ha a fizetés megtörtént. Ellenőrizned kell azon a háttérfelületen, ahol a fizetést elfogadod, hogy megjött-e a pénz, és értesítened kell a vevőt."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez egy nyílt forráskódú projekt. Klónozható, módosítható, vagy üzleti céllal a saját környezetedben futtatható."])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési gateway egy HTML webhely, amely JavaScript-et futtat. A hivatalos Algorand csomópontokon kívül semmilyen más hátteret nem használ."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gateway használatához nem szükséges AWallet számlával rendelkezni."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megengedett paraméterek"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A paraméterek bármelyike állítható. Kötelezően megadandó mezők: az a cím, ahol fogadni szeretnéd a fizetséget, és a tranzakció összege. A fizetési cím két részből áll: a fizetési adatból és a gateway beállításokból. Mindkét mező base64 kódolású, és egy / jel választja el őket egymástól. Vigyázz rá, hogy mindkettőt megfelelően kódold. A fizetési adat nem más, mint az az Algorand protokoll cím, amelyet pl. a QR-kódokban használnak."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megengedett paraméterek"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a pénzeszköz alapegységének a használata. Pl. 1 Algo = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["az összeg mikroalgókban. Pl. 0,001 Algo = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belövéshez használt hálózat"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megengedett beállítások"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL cím, ahová a kliens a sikeres fizetés után lesz átirányítva"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL cím, ahová a kliens a 'fizetés törlése' választása után lesz átirányítva"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Példa"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A build eszköz bekapcsolása"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A build eszköz kikapcsolása"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megadható a tranzakcióhoz a pénzeszköz azon."])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, töltse ki a számlát, az összeget és a pénzeszközt"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az URL címnek a https:// protokollal kell kezdődnie"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generált nyomógomb"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési információ"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback konfiguráció"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generált hiperlink"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML hiperlink példa"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés fogadása"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés fogadása ezen a címen"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedes jegyek száma a QR-kód számára"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fogadó fél címéhez tartozó címke"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megjegyzés mezőt a felhasználó szerkesztheti"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla áttekintés"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új kifizetés létrehozása"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés fogadása"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg a függő jutalmak nélkül"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jutalmak"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Függő jutalmak"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jutalom alapja"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menet"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App-ok lokális állapota"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App-ok teljes sémája"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszközök"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozott app-ok"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Státusz"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többszörös aláírási küszöb"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többszörösen aláírt címek"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frissítés"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakciók"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs egyetlen tranzakció sem"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldő"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fogadó"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fogadó jutalma"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősített menetek száma"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla törlése"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla törlése"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan törölni szeretnéd ezt a számlát a pénztárcából?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a számlának a törlése"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla törölve lett"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz engedélyezés"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérdésem van"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy adott pénzeszköz fogadásának engedélyezése, számla:"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudom, hogy mi a pénzeszköz azonosítója"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz azonosító"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz név"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz keresés"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ a pénzeszközről"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egység neve"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedes jegyek"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metaadat hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdetben befagyasztva"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozó címe"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezelő címe"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszakövetelő címe"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befagyasztó címe"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibocsátó címe"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Művelet"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benevezés"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénzeszköz fogadásának engedélyezése"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biztosan ki akarsz fizetni 0,001 Algót, hogy engedélyezd ennek az eszköznek a fogadásást? Az engedélyezés után képes leszel arra, hogy ilyen eszközt a fogadj a számládon."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engedélyezni akarom a pénzeszköz fogadását"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs ilyen pénzeszköz"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikeresen engedélyezted az eszköz fogadását"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlák áttekintése"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs egyetlen egy számla sem"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla neve"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifizetés"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új számla"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyszerű számla létrehozása"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla importálása"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többszörösen aláírt számla létrehozása"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megfigyelési számla"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írd le a 25 szavas mnemonikus kifejezést"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső számlanév"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számla létrehozás"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A többszörösen aláírt számla csak akkor dolgozza fel a tranzakciókat, ha a számla létrehozásakor kilistázott N számla aláírja a tranzakciót."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassz egy létező számlát a pénztárcádban"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add meg a barátaid számláit - soronként egyet"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válaszd ki, hogy hány számla szükséges egy tranzakció aláírásához"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A számla létrehozásakor mentsd el a mnemonikot. Biztonságos, ha a kifejezés megjelenítése most történik?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mnemonik megjelenítése"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milyen szó van az n-ik helyen"])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Írd le a mnemonikokat a megjelenítés sorrendjében, másold le a szavakat vagy készíts egy fényképet. A következő lépésben ennek a kifejezésnek az egyes szavait kell megerősíteni, majd titkosított formában elmentjük a kifejezést a böngésző tárába. A mnemonik tárolása és a cím használata biztonságosan történik. Vigyázz rá, hogy ne veszítsd el a mnemonikus kifejezést."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kihívás kezdete"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új készítése"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mnemonik elrejtése"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kifejezés elvetése"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR-kód pásztázás"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérkedő számlanév"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím ezzel kezdődik:"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím tartalmazza:"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cím ezzel végződik:"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feldolgozott címek száma"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím keresése"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés leállítása"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használni kívánt szálak száma"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek a számlának a használata"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az automatikus zárolás funkció kikapcsolva"])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca importja"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca neve"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárcához tartozó állomány"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca importálása"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előzetesen kiexportált pénztárcák beimportálhatók. A pénztárca jelszó az exportált állományon belül van, ezért a pénztárca az importálás után ugyanazzal a jelszóval használható. A bejelentkezés után a jelszó a 'Beállítások' lapon változtatható meg."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyszerű számla"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többszörösen aláírt számla"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyilvános számla"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifizetés indítása innen: "])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképezőgép be/kikapcsolása"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Megjegyzés base64 kódolású. Ha engedélyezed, akkor base64 formátumban adhatod meg az inputot a megjegyzés mezőben, és ilyen formátumban küldheted el a hálózatnak."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiválasztott számla"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többszörösen aláírt számla"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat készítése"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajánlat aláírása & elküldése"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, itt add be a barátodtól származó aláírást"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Többszörösen aláírt adat betöltése"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés a pénztárcában lévő számlának"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés egy másik számlának"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobb, ha azt a számlát, aminek a kifizetés történik, a címtárban tárolod"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kifizetés áttekintése"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, tekintsd át a kifizetést"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erről a számláról"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés erre a számlára"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció részletei"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Típus"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Név"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FirstRound"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LastRound"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kinek"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírások"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírva"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs aláírva"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás ezzel"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek az adatnak az elküldése a többi aláírónak"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elküldés a hálózatnak"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utalás elküldése a hálózatnak"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utalás elküldve a hálózatnak. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várakozás a hálózat megerősítésére."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A megerősítés megérkezett. Az utalás bekerült a blokkba"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az utalás valószínűleg nem jutott el a hálózatra. Offline módba kerültél? Kérjük, ellenőrizd a számládat."])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum beállítása"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beállítások"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerver"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["környezet"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devnet elérése"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gazdagép"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca jelszó"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régi jelszó"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új jelszó - megismételve"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca mentés"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pénztárca elmenthető és egy másik számítógépen beimportálható."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés készítése"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letöltés"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pénztárca törlés"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erősség"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jelszó módosult"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egyedi szerver beállítások"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokoll kezelő regisztrálása"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["web+algorand:// kezelő regisztrálása"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A protokoll kezelő módosítása sikeres volt"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció részletei"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció ID"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tranzakció típus"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formázatlan adat"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekódolt adat"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jutalmak lezárása"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záráskori összeg"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megerősített menet"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Díj"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Első érvényes"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genezis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genezis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belső eltolás"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utolsó érvényes"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési tranzakció"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összeg"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záráskori összeg"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címzett"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Címzett jutalma"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy menet ideje"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldő"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldő jutalma"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aláírás"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A létrejött eszköz"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz neve"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz létrehozó"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz kezelő"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz kibocsájtó"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz befagyasztó"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz visszakövetelő"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz egységének a neve"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összes eszköz"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedesjegyek száma"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kezdetben legyen befagyasztva"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eszköz metaadat"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idő"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adatvédelmi irányelvek"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milyen információkat gyűjtenek rólam?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semmilyeneket, az IP címeden és böngésző típusodon kívül, amit minden internet szolgáltató tárol. Nem gyűjtünk semmilyen személyes adatot sem, pl. neveket, e-mail címeket vagy Algorand címeket."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi van sütikben tárolva?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semmi. Nem használunk sütiket. Egy indexdb nevű perzisztens lokális tárat használunk a pénztárca tárolására."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hogyan történik az analitikai adatok gyűjtése?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem gyűjtünk ilyen adatokat. Semmilyen analitikai szolgáltatást sem használunk, pl. a Google analitikát sem, hogy a felhasználók nagyobb biztonságban érezhessék magukat. Az alkalmazás tisztán html és javascript, és csak akkor tudjuk meg az IP címedet, ha egy kérést küldesz a szervernek. A beállításaidtól és az algod szerveredtől ill. indexelő szolgáltatásodtól függően a kiválasztott szerver szintén tudomást szerezhet az IP címedről."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érinti-e ennek a szolgáltatásnak a használata, hogy milyen reklámokat látok?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem. A reklám szolgáltatók semmilyen adatot sem kapnak tőlünk a használatra vonatkozóan. Például az egyetlen mód, amivel a Google tudomást szerez róla, hogy használod ezt a szolgáltatást, ha a webhelyet a Google keresésből éred el."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ki nyújtja a szolgáltatást?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Scholtz & Company, Kkt. hozta létre ezt a szolgáltatást. Ha a pénztárca a Scholtz & Co által biztosított hivatalos web helyen fut, akkor a cég egyúttal a szolgáltatás nyújtó. A cég nem felel semmilyen szolgáltatásért, sem az alkalmazás esetleges hibáiért."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Új NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haladóknak szóló űrlap megjelenítése"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barátságos név"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A létrehozó címe"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haladóknak szóló űrlap elrejtése"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím létrehozása vagy először cím beimportálása"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befagyasztva: a felhasználói számlákat fel kell oldani a tranzakciók végzése előtt"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tizedesjegyek: a tizedes jegyek számát megadó egész szám, mely az eszköz egységeinek a számítására használatos"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennek az eszköznek a forgalomban lévő összes darabszáma"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arra használjuk, hogy az eszköz egységeit megjelenítsük a felhasználó számára"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy opcionális string, amely az eszközhöz tartozó URL-re mutat"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egy opcionális hash, amely valamilyen módon az eszközhöz kapcsolódik. 32 karakter hosszú."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A kezelő módosítani tudja az eszköz kezelőjének, kibocsátójának, befagyasztójának és visszakövetelőjének a számlacímét"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kibocsátó cím: A megadott címet tekinti az eszköz kibocsátójának (nincs semmi különös privilégiuma, csak informális)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befagyasztási cím: A megadott cím képes a felhasználó pénzeszközének befagyasztására vagy a befagyasztás feloldására"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszakövetelési cím: A megadott cím képes a felhasználó pénzeszközét visszavenni és elküldeni egy másik címre"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tranzakció létrehozásakor megadott megjegyzés"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT / ASA létrehozás"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gyakran ismételt kérdések"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the vote coin voting system"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decentralized voting platform based on Algorand message standard 0001 was winner solution at encode hackathon and later participated in encode accelerator program. Main advantage of this voting system is cost effeciency and auditable results by algorand blockchain. Vote coin, similar as algorand governance uses the note field in the transactions as the data storage. Vote coin provides technical solution for knowledge based democracy. Person can delegate his voting power to other person. Delegation may be partial, one can give 50 points to person A and 100 points to person B. Delegation can be also content specific. If person trusts person A in economy matters he can delegate his full voting power to him, while he can delegate full voting power in matter of IT tech to person B. Casting of the votes can be changed, and the last vote in the voting round is valid. Voting can be used as 1 vote = 1 vote, or 1 person = 1 vote. Quadratic voting can be applied if DAO requires it. Voting can be used by DAOs, corporations, governments or individuals. Audit services may be purchased."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I have my custom ASA as basis for 1 vote = 1 ASA token?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. This is the main use case. DAO usually has distributed their tokens to the owners. DAO asks a question which costs 0.001 algo. DAO members vote or delegate their voting power to other members. After voting is finished, auditors publish the results. At the end, DAO calculates results or takes the results of the auditors, and publish the final vote result. From this message the action may be triggered, for example the github PR merge."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is delegation pernament?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the delegation within scope of the asset is pernament until it has been changed. When person trusts the politician, he delegates him his voting power. Person can change his delegation preferences any time. The decision point for vote calculation is the final block of the voting. If person A votes and person B does not vote, if person B delegate his voting power to person A 1 block before end of the voting, his voting power for this voting session will be transfered."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you call it knowledge based pure democracy system?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregation of wisdom is ensured by categorized delegation. Every question is context based. Some questions requires IT knowledge, some questions requires knowledge of laws. If person A trusts person B and C in IT matters, he can delegate for example 20 IT points to person B, 30 IT points to person C, and 20 Legal points to person D. If there is question regarding the IT stuff, for example github PR merge request and person A does not vote, he delegates his voting power to 20/50 to person B, and 30/50 to person C. Person B can delegate his voting power to other persons he knows they do good decisions, for example 100 IT points to person C. If only person C votes, he receives the voting power from person A, B, and himself. Delegation of power does not mean that person cannot vote. It is pure democratic system which means that if person votes, he always represents his voting power, and his voting power is not being used by anyone else."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help the project?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please spread the word on youtube, twitter or other means. We have active engagement program where you can receive Vote Coin tokens."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are developer, please take a look at the engagement program. If you create open source solution based on the vote coin standard, you can receive vote coin token rewards."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you represent a DAO or corporation, please support us by purchasing Auditing services. We will calculate the results of voting for you so that you can be ensured you have the correct results."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much will it cost me?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are DAO, it will cost you 0.002 algo per question - you ask a question through blockchain, and set final results."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your DAO members will pay pay 0.001 algo to cast votes. They can delegate their voting power for 0.001 to other persons so that they do not have to vote in each question, but rather delegate their voting power to trusted person."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If DAO wants to manage their own list of allowed person, in one transaction it can add approx 15 accounts to the trusted list."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are single person who wants to ask a question, just place a question to the algo token."])}
  }
}